.footer {
  display: flex;
  justify-content: center;
  margin-left: 10%;
  margin-right: 10%;
}

.accordion-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: flex-start;
  justify-content: space-between;
  width: 80%;
  margin: 2rem;
}

@media (max-width: 600px) {
  .footer {
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    margin: 0;
  }

  .accordion-row {
    padding: 0;
    width: 100%;
    margin: 0;
  }
}
