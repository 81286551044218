@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

.navbar {
  height: 60px;
  background-color: transparent;
  position: fixed;
  z-index: 999999;
  width: 100%;
}

.header-container {
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  background: transparent;
}

.logo {
  font-size: 36px;
  font-weight: bold;
  color: white;
  padding-left: 40px;
}

.menu-icon {
  display: none;
}

.nav-elements {
  transition: all 0.3s ease-in;
}
.nav-elements ul {
  display: flex;
  list-style-type: none;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  text-decoration: none;
  color: white;

  font-size: 22px;
  font-weight: bold;
  margin: 1.5rem 1rem;
  padding: 1.9rem;
  position: relative;
}

.nav-elements ul a.active {
  color: #574c4c;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
}

@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 600px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }
  .header-container {
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
  .nav-elements {
    position: absolute;
    right: 0;
    top: 60px;
    background-color: black;
    width: 0px;
    height: 100vh;
    transition: all 0.5s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 100%;
  }

  .nav-elements {
    font-size: 52px;
  }

  .nav-elements ul {
    display: flex;

    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
  .nav-elements ul li a {
    margin-right: unset;
    margin-top: 22px;
  }
}
