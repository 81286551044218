.faq-wrapper {
  margin: 0;
  background: black;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.main-section {
  background: black;
}
.faq-container {
  align-items: center;
  justify-content: center;
}
.fullwidth-container {
  margin: 2rem 10%;
  display: flex;
  flex-wrap: wrap;
}

.line-heading {
  font-family: sans-serif;
  color: #ccd6f6;
  font-weight: 600;
  line-height: 1.1;
  display: flex;
  align-items: center;
  width: 0%;
  margin: 10px 0 40px 10%;
  white-space: nowrap;
  font-size: 30px;
  text-shadow: 1px 1px 1px rgb(0, 0, 0);
  text-align: center;
}
.line-heading:after {
  content: "";
  display: block;
  position: relative;
  width: 300px;
  height: 1px;
  margin: 0 0 0 20px;
  background: rgba(204, 214, 246, 0.2);
  text-shadow: none;
  transition: all 0.6s ease-in-out 0s;
}
.line-heading:hover:after {
  width: 180px;
  background: rgba(204, 214, 246, 0.6);
}
h1.large-heading {
  font-family: sans-serif;
  margin: 0 0 0 10%;
  font-size: 37px;
  line-height: 1.1;
  letter-spacing: -0.045em;
  color: #fff;
  text-shadow: 1px 1px 1px rgb(0 0 0);
}

.myFooter {
  float: left;
  width: 100%;
  display: block;
  background: black;
  text-align: center;
  height: 10vh;
}
.myFooter p {
  color: #fff;
  font-family: sans-serif;
  text-shadow: 1px 1px 0 rgb(0 0 0);
}
.myFooter a {
  color: #00c58e;
  text-decoration: none;
}

@media (max-width: 600px) {
  .line-heading {
    font-size: 26px;
    display: flex;
    margin: 10px 0 40px 5%;
    width: 80%;
  }
  .line-heading:after {
    display: none;
  }
  h1.large-heading {
    margin: 10px 0 40px 5%;
    font-size: 19px;
    width: 80%;
  }
}
