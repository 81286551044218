.animated {
  transition: opacity 4.1s;
  object-fit: cover;
}

.animated.active {
  opacity: 1;
}

.animated:not(.active) {
  opacity: 0;
  position: absolute;
}

.container {
  height: 100%;
  position: absolute;
}

.animated {
  position: sticky;
  top: 0;
}

.fresh {
  position: relative;
}

.animated {
  width: 100%;
  display: none;
}

.animated.active {
  display: flex;
  flex-direction: column;
}
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #214257;
}

.loading-bar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 4px solid #ffffff;
  border-top: 4px solid #214257;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 767px) {
  .container {
    /* height: 100vh; */
  }

  .animated {
    position: fixed;
    top: auto;
    height: 100%;
    object-fit: fill;
  }

  .fre {
    position: static;
    top: auto;
    left: auto;
    padding-top: 0;
    width: auto;
    height: auto;
  }
}

.scroll-message {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2em;
  color: white;
  text-align: center;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
}
