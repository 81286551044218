body,
html {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  background-color: black;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #333;
}
